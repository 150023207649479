import { urijs } from '../urijs';
import { reduce, endsWith } from 'lodash';
import { readableUrl } from './uri';
export var getCategoryUrl = function (sectionUrl, path, slug) {
    if (sectionUrl) {
        var segments = [path, slug];
        if (endsWith(slug, '/')) {
            segments.push('');
        }
        return readableUrl(reduce(segments, function (url, segment) { return url.segment(segment); }, new urijs(sectionUrl)).toString());
    }
    return '';
};
