import hashtagRegexes from './hashtag-regexes';

export default function extractHashtags(text) {
  if (!text || !text.match(hashtagRegexes.hashSigns)) {
    return [];
  }

  const tags = [];

  // eslint-disable-next-line max-params
  text.replace(
    hashtagRegexes.validHashtag,
    (match, before, hash, hashText, offset, chunk) => {
      const after = chunk.slice(offset + match.length);
      if (after.match(hashtagRegexes.endHashtagMatch)) {
        return;
      }
      const startPosition = offset + before.length;
      const endPosition = startPosition + hashText.length + 1;
      tags.push({
        hashtag: hashText,
        indices: [startPosition, endPosition],
      });
    },
  );

  return tags;
}
