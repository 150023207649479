import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './image-loader.scss';

const ImageLoader = ({ className, type, withOverlay }) => {
  const Loader = () => (
    <div
      className={classNames(styles.loader, className, 'image-loader', {
        [styles[type]]: type,
      })}
    />
  );
  return withOverlay ? (
    <div className={styles.loaderOverlay}>
      <Loader />
    </div>
  ) : (
    <Loader />
  );
};

ImageLoader.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  withOverlay: PropTypes.bool,
};

export default ImageLoader;
