import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP } from '@wix/communities-blog-experiments';
import Header from '../../../common/components/header';
import Link from '../../../common/components/link/internal-link';
import HeaderBackButton from '../../components/header-back-button';
import SearchHeader from './search-header';
import withExperiment from '../../../common/hoc/with-experiment';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import styles from './search-mobile-header.scss';

const SearchMobileHeader = ({
  contentFontClassName,
  isMobileMenuColorMatchDesktopExperimentEnabled,
  ...otherProps
}) => {
  const left = (
    <div className={styles.backButton}>
      <HeaderBackButton arrow text="" component={Link} to="/" />
    </div>
  );

  const inputClassName = isMobileMenuColorMatchDesktopExperimentEnabled
    ? classNames(
        'blog-navigation-container-color',
        'blog-navigation-container-font',
        styles.input,
      )
    : classNames('blog-button-primary-text-color', contentFontClassName);

  const borderClassName = isMobileMenuColorMatchDesktopExperimentEnabled
    ? 'blog-navigation-container-separator-background-color'
    : 'blog-button-primary-text-background-color';

  const iconClassName = isMobileMenuColorMatchDesktopExperimentEnabled
    ? 'blog-navigation-container-fill'
    : 'blog-button-primary-icon-fill';

  return (
    <SearchHeader
      component={Header}
      left={left}
      inputClassName={inputClassName}
      borderClassName={borderClassName}
      iconClassName={iconClassName}
      showWidget={false}
      {...otherProps}
    />
  );
};

SearchMobileHeader.propTypes = {
  contentFontClassName: PropTypes.string,
  isMobileMenuColorMatchDesktopExperimentEnabled: PropTypes.bool,
};

export default flowRight(
  withFontClassName,
  withExperiment({
    isMobileMenuColorMatchDesktopExperimentEnabled:
      EXPERIMENT_MOBILE_MENU_COLOR_MATCH_DESKTOP,
  }),
)(SearchMobileHeader);
