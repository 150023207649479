import { trimStart } from 'lodash';
import { readableUrl, getOuterUrl } from '@wix/communities-blog-client-common';

export const encodeQuery = (query) =>
  query &&
  encodeURIComponent(
    query.toLowerCase().replace(/#/g, '.hash.').replace(/\s/g, '-'),
  );
export const decodeQuery = (query) =>
  query &&
  decodeURIComponent(query)
    .replace(/\.hash\./g, '#')
    .replace(/-/g, ' ');

export const createHashtagPath = (decoratedText) => {
  const hashtag = `#${trimStart(decoratedText, '#')}`;
  return readableUrl(`/search/${encodeQuery(hashtag)}`);
};

export const createHashtagHref = (decoratedText, sectionUrl) =>
  getOuterUrl(createHashtagPath(decoratedText), sectionUrl);
