import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import Highlighter from '../highlighter';
import PostListItemHeader from '../../../common/components/post-list-item-header';
import PostTitle from '../../../common/components/post-title';
import PostFooter from '../../../common/components/post-footer';
import { HorizontalSeparator } from '../../../common/components/separator';
import withFeedBorderWidth from '../../../common/hoc/with-feed-border-width';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import withIsFeedDesignEnabled from '../../../common/hoc/with-is-feed-design-enabled';
import { getFeedColorClassName } from '../../../common/services/layout-config';
import { getContentText } from '../../../common/services/content-text';

import styles from './post-search-list-item.scss';

export const PostSearchListItem = ({
  post,
  query,
  onLikeClick,
  displayFooterIcons,
  postDetailsLineCount,
  borderWidth,
  titleFontClassName,
  contentFontClassName,
  contentFontClassNameWithStyle,
  postTitleFontSize,
  postDescriptionFontSize,
  postMetadataFontSize,
  applyFeedDesign,
  getPostClassName,
  layoutType,
}) => {
  const contentFontClass = applyFeedDesign
    ? getPostClassName('description-font')
    : contentFontClassName;
  const contentFontClassWithStyle = applyFeedDesign
    ? getPostClassName('description-style-font')
    : contentFontClassNameWithStyle;

  const containerClassName = classNames(
    styles.container,
    contentFontClass,
    !applyFeedDesign && [
      'blog-text-color',
      'blog-card-background-color',
      'blog-card-border-color',
    ],
    'post-list-item',
    getPostClassName(
      getFeedColorClassName(layoutType, 'description-color'),
      getFeedColorClassName(layoutType, 'background-color'),
      'border-color',
    ),
  );
  const postLink = `/${post.slug}`;
  const type = 'search';
  const maxLength = 700;
  const text = post.excerpt
    ? post.excerpt.slice(0, maxLength)
    : getContentText(post.content).slice(0, maxLength);
  const title = query ? (
    <Highlighter text={post.title} query={query} />
  ) : (
    post.title
  );

  return (
    <article
      className={containerClassName}
      tabIndex="0"
      style={{ borderWidth }}
      data-hook="post-list-item"
    >
      <div className={styles.contentWrapper}>
        <div
          style={{ fontSize: postMetadataFontSize }}
          className={classNames(styles.header, contentFontClass)}
        >
          <PostListItemHeader type={type} post={post} />
        </div>
        <div
          style={{ fontSize: postTitleFontSize }}
          className={classNames(styles.title, titleFontClassName)}
        >
          <PostTitle
            fullRoute={post.link}
            type={type}
            title={title}
            to={postLink}
          />
        </div>
        <div
          style={{ fontSize: postDescriptionFontSize }}
          className={classNames(styles.content, contentFontClassWithStyle)}
          data-hook="post-description"
        >
          <p
            className={styles.text}
            style={{ WebkitLineClamp: postDetailsLineCount }}
          >
            {query ? <Highlighter text={text} query={query} /> : text}
          </p>
        </div>
        <HorizontalSeparator
          className={classNames(
            styles.separator,
            getPostClassName('description-background-color'),
          )}
        />
        <div
          style={{ fontSize: postMetadataFontSize }}
          className={classNames(styles.metaContainer, contentFontClass)}
        >
          <PostFooter
            post={post}
            onLikeClick={onLikeClick}
            displayIcons={displayFooterIcons}
            type={type}
          />
        </div>
      </div>
    </article>
  );
};

PostSearchListItem.SEARCH = 'search';
PostSearchListItem.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  post: PropTypes.object,
  query: PropTypes.string,
  displayFooterIcons: PropTypes.bool,
  postDetailsLineCount: PropTypes.number.isRequired,
  borderWidth: PropTypes.number,
  titleFontClassName: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  contentFontClassNameWithStyle: PropTypes.string.isRequired,
  postTitleFontSize: PropTypes.number.isRequired,
  postDescriptionFontSize: PropTypes.number.isRequired,
  postMetadataFontSize: PropTypes.number.isRequired,
  applyFeedDesign: PropTypes.bool.isRequired,
  getPostClassName: PropTypes.func.isRequired,
  layoutType: PropTypes.number,
};

PostSearchListItem.defaultProps = {
  postTitleFontSize: 28,
  postDescriptionFontSize: 16,
  postMetadataFontSize: 12,
  postDetailsLineCount: 4,
};

// prettier-ignore
export default flowRight(
  withFeedBorderWidth,
  withFontClassName,
  withIsFeedDesignEnabled,
)(PostSearchListItem);
