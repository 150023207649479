import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from '../../../common/components/runtime-context';

import { POST_LIST_COMPONENT_MASONRY } from '@wix/communities-blog-client-common';
import { getLayoutConfig } from '../../../common/services/layout-config';
import MasonryWrapper from '../masonry-wrapper';
import Loader from '../../../common/components/loader';
import withDeviceType from '../../../common/hoc/with-device-type';
import withComponents from '../../../common/components/components-provider/with-components';
import LoadMore from '../../../common/components/load-more';
import OnScreen from '../../../common/components/on-screen';
import withLayoutProps from '../../../common/hoc/with-layout-props';
import withFeedMetadataSettings from '../../../common/hoc/with-feed-metadata-settings';
import { getIsPostListFullWidthEnabled } from '../../../common/selectors/app-settings-selectors';
import { getIsLazyPaginationEnabled } from '../../../common/selectors/pagination-selectors';
import RemainingPostLoader from './remaining-post-loader';

import styles from './post-list.scss';
import { getPostListCssVars } from '../../../common/services/post-list-css-vars';

class PostList extends Component {
  renderList(
    listComponentName,
    itemComponentName,
    itemConfig,
    showLoader = false,
  ) {
    const { allPosts, entityCount, layoutName, isLoading, ...props } =
      this.props;

    const ListComponent = this.props[listComponentName];
    const ItemComponent = this.props[itemComponentName];
    return (
      <div style={getPostListCssVars()}>
        <ListComponent
          key="posts"
          posts={allPosts}
          entityCount={entityCount}
          type={layoutName}
          ItemComponent={ItemComponent}
          itemConfig={itemConfig}
          {...props}
        />
        {showLoader && isLoading && (
          <Loader dataHook="post-list__loader" key="loader" />
        )}
      </div>
    );
  }

  renderLayout = ({ listComponentName, itemComponentName, itemConfig }) => {
    const {
      isLoading,
      allPosts,
      entityCount,
      isLazyPaginationEnabled,
      page,
      pageSize,
      loadRest,
      loadMore,
      pageStart,
      currentPagePosts,
    } = this.props;

    if (loadRest && isLazyPaginationEnabled) {
      const totalRemainingPostCount =
        entityCount - (page - 1) * pageSize - currentPagePosts.length;
      const hasRemainingPosts =
        currentPagePosts.length < pageSize && totalRemainingPostCount > 0;

      return (
        <>
          {this.renderList(
            listComponentName,
            itemComponentName,
            itemConfig,
            true,
          )}
          {currentPagePosts.length > 0 && hasRemainingPosts && (
            <OnScreen>
              {({ isOnScreen, setRef }) => {
                if (!isOnScreen) {
                  return <div ref={setRef} />;
                }
                return <RemainingPostLoader loadRemainingPosts={loadRest} />;
              }}
            </OnScreen>
          )}
        </>
      );
    }

    if (loadMore) {
      return (
        <LoadMore
          loadMore={loadMore}
          loader={<Loader />}
          isLoading={isLoading}
          hasMore={allPosts.length < entityCount}
          pageStart={pageStart}
        >
          {this.renderList(listComponentName, itemComponentName, itemConfig)}
        </LoadMore>
      );
    }

    return this.renderList(
      listComponentName,
      itemComponentName,
      itemConfig,
      true,
    );
  };

  render() {
    const {
      layoutType,
      layoutName,
      isMetadataFooterVisible,
      isPostListFullWidthEnabled,
    } = this.props;

    const layoutConfig = getLayoutConfig(layoutType);

    const containerClassName = classNames(
      styles.container,
      isPostListFullWidthEnabled && styles.fullWidth,
      styles[layoutName],
      !isMetadataFooterVisible && styles.withoutFooter,
      'post-list',
    );

    return (
      <div className={containerClassName}>
        <MasonryWrapper
          isEnabled={
            layoutConfig.listComponentName === POST_LIST_COMPONENT_MASONRY
          }
        >
          {this.renderLayout(layoutConfig)}
        </MasonryWrapper>
      </div>
    );
  }
}

PostList.propTypes = {
  onLikeClick: PropTypes.func.isRequired,
  loadMore: PropTypes.func,
  loadRest: PropTypes.func,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  isLazyPaginationEnabled: PropTypes.bool,
  currentPagePosts: PropTypes.array,
  category: PropTypes.object,
  allPosts: PropTypes.array,
  location: PropTypes.object,
  isMobile: PropTypes.bool,
  layoutType: PropTypes.number.isRequired,
  layoutName: PropTypes.string.isRequired,
  entityCount: PropTypes.number,
  pageStart: PropTypes.number,
  isLoading: PropTypes.bool,
  showCreatePostAction: PropTypes.bool.isRequired,
  isMetadataFooterVisible: PropTypes.bool,
  isPostListFullWidthEnabled: PropTypes.bool.isRequired,
  PostListSimple: PropTypes.elementType.isRequired,
  PostListMasonry: PropTypes.elementType.isRequired,
  PostListMobile: PropTypes.elementType.isRequired,
  PostListItem: PropTypes.elementType.isRequired,
  Post: PropTypes.elementType.isRequired,
  SideBySideMobile: PropTypes.elementType.isRequired,
  TextOnImageMobile: PropTypes.elementType.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  scroll: state.scroll,
  isPostListFullWidthEnabled: getIsPostListFullWidthEnabled(state),
  isLazyPaginationEnabled: getIsLazyPaginationEnabled(state),
  onLikeClick: actions.incrementPostLikeCount,
});

export default flowRight(
  connect(mapRuntimeToProps),
  withComponents,
  withDeviceType,
  withFeedMetadataSettings,
  withLayoutProps(),
)(PostList);
