// TODO: this needs to read from the settings in the DB. this will ensure that the forum will be out of wix like it is in wix
export var getMainColor = function () {
    return '#27a456';
};
export var getSecondaryColor = function () {
    return '#faad4d';
};
export var getSecondaryColorNumbers = function () {
    var color = getSecondaryColor();
    var matches = color.match(/#([\da-f]{2})([\da-f]{2})([\da-f]{2})/i);
    var numbers = matches.slice(1).map(function (number) { return parseInt(number, 16); });
    return numbers;
};
export var getDisabledColor = function () {
    return '#f0f0f0';
};
