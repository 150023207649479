import PropTypes from 'prop-types';
import React from 'react';
import EmptyStates from '../../../common/components/empty-states';
import Page from '../../../common/components/page';
import withTranslate from '../../../common/hoc/with-translate';
import withCardBorderWidth from '../../../common/hoc/with-card-border-width';
import styles from './search-page.scss';

const SearchPage = ({ t, borderWidth }) => {
  return (
    <Page>
      <div style={{ marginTop: -borderWidth }}>
        <EmptyStates
          className={styles.emptyStatesContainer}
          title={t('search-page.no-results-title')}
          content={t('search-page.no-results-content')}
        />
      </div>
    </Page>
  );
};

SearchPage.propTypes = {
  t: PropTypes.func.isRequired,
  borderWidth: PropTypes.number,
};

export default withTranslate(withCardBorderWidth(SearchPage));
