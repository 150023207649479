export var COMMENTS_PER_PAGE = 20;
export var LIKES_PER_PAGE = 20;
export var MEMBERS_PER_PAGE = 18;
export var POSTS_PER_PAGE = 20;
export var MAX_POSTS_PER_PAGE = 24;
export var MAX_POSTS_PER_PAGE_MOBILE = 5;
export var MAX_POSTS_PER_PAGE_FULL_POST = 3;
export var ARCHIVE_POSTS_PER_PAGE = 20;
export var MANAGED_POSTS_PER_PAGE = 40;
export var PAGINATION_TYPE_INFINITE_SCROLL = 0;
export var PAGINATION_TYPE_MULTIPLE_PAGES = 1;
