import PropTypes from 'prop-types';
import React from 'react';
import styles from './default-desktop-layout.scss';

const DefaultDesktopLayout = ({ children }) => (
  <div className={styles.container}>
    <div className={styles.content}>{children}</div>
  </div>
);

DefaultDesktopLayout.propTypes = {
  children: PropTypes.node,
};

export default DefaultDesktopLayout;
