import React from 'react';
import EventEmitter from 'wolfy87-eventemitter';
import hoistNonReactStatics from 'hoist-non-react-statics';
import getDisplayName from '../services/get-display-name';

const emitter = new EventEmitter();

export default function withEventEmitter(WrappedComponent) {
  const Wrapper = (props) => {
    return <WrappedComponent eventEmitter={emitter} {...props} />;
  };

  Wrapper.displayName = `WithEventEmitter(${getDisplayName(WrappedComponent)})`;

  hoistNonReactStatics(Wrapper, WrappedComponent);

  return Wrapper;
}
