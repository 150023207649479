import PropTypes from 'prop-types';
import React from 'react';
import withTranslate from '../../../common/hoc/with-translate';
import withCardBorderWidth from '../../../common/hoc/with-card-border-width';
import styles from './no-search-results.scss';
import EmptyStates from '../../../common/components/empty-states';

export const NoSearchResults = ({ borderWidth, t }) => (
  <div style={{ marginTop: -borderWidth }}>
    <EmptyStates
      className={styles.container}
      title={t('no-search-results.no-results-found-title')}
      content={t('no-search-results.try-again')}
    />
  </div>
);

NoSearchResults.propTypes = {
  borderWidth: PropTypes.number.isRequired,
  t: PropTypes.func,
};

export default withTranslate(withCardBorderWidth(NoSearchResults));
