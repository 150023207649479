import { flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';

import {
  SECTION_HOMEPAGE,
  isLayoutProGallery,
  createPageUrl,
  FEED_LAYOUT_PARAMS,
} from '@wix/communities-blog-client-common';
import './styles.scss';

import { handleNavigation } from '../../../common/services/navigation';
import {
  getFeedPosts,
  getFeedPostsByPage,
} from '../../../common/selectors/post-selectors';
import { getIsFeedPostsLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';
import { getIsFeedPostsLoading } from '../../../common/store/is-loading/is-loading-selectors';
import {
  getBasicParams,
  getIsMobile,
  isEditor,
  isSite,
} from '../../../common/store/basic-params/basic-params-selectors';
import { isExcludePostContentSupported } from '../../../common/selectors/layout-selectors';
import { simulateComponentError } from '../../../common/services/simulate-error';
import {
  getIsFeaturedPostBannerEnabled,
  getCanSeeMoreButton,
} from '../../../common/selectors/app-settings-selectors';
import withAuth from '../../../common/hoc/with-auth';
import withDeviceType from '../../../common/hoc/with-device-type';
import withPaginationSettings from '../../hoc/with-pagination-settings';
import withPermissions from '../../../common/hoc/with-permissions';
import withLayoutProps from '../../../common/hoc/with-layout-props';
import withComponents from '../../../common/components/components-provider/with-components';
import AnimatedLoader from '../../../common/components/animated-loader';
import AppLoaded from '../../../common/components/app-loaded';
import FeaturedPostBanner from '../featured-post-banner';
import NoPostsFeed from '../../../common/containers/no-posts-feed';
import Page from '../../../common/components/page';
import Pagination from '../../../common/containers/pagination';
import PostList from '../../components/post-list';
import { isCreatePostButtonVisible } from '../../../common/selectors/blog-menu-button-selectors';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import { getAppSettingsValue } from '../../../common/selectors/app-settings-base-selectors';

export class FeedPage extends Component {
  state = { isAnimated: !this.props.isLoaded };

  componentDidMount() {
    simulateComponentError(this.props.basicParams, 'feed-page.container');
  }

  componentDidUpdate({ pageSize, excludeContent }) {
    if (
      this.props.pageSize !== pageSize ||
      excludeContent !== this.props.excludeContent
    ) {
      this.fetchFeedPosts();
    }
  }

  fetchFeedPosts(page = this.props.page) {
    if (this.props.isBlocked) {
      return;
    }
    return this.props.fetchFeedPosts({
      page,
      excludeContent: this.props.excludeContent,
    });
  }

  loadMore = (page) => this.fetchFeedPosts(page);
  loadRest = () => this.fetchFeedPosts(this.props.page);

  renderPosts() {
    const {
      currentPagePosts,
      posts,
      entityCount,
      page,
      isLoading,
      isLoaded,
      showCreatePostAction,
      showPagination,
      layoutType,
      isEditor,
      PostListProGallery,
      canSeeMoreButton,
      pageSize,
    } = this.props;
    const hasPosts = posts.length;

    if (isLoaded && !hasPosts) {
      return <NoPostsFeed />;
    }

    if (isLayoutProGallery(layoutType)) {
      return (
        <PostListProGallery
          pageStart={page + 1}
          entityCount={entityCount}
          loadMore={showPagination ? undefined : this.loadMore}
          allPosts={showPagination || isEditor ? currentPagePosts : posts}
          currentPagePosts={currentPagePosts}
          isLoading={isLoading}
          showCreatePostAction={showCreatePostAction}
          canSeeMoreButton={canSeeMoreButton}
          layoutOptions={this.props.layoutOptions}
          section={SECTION_HOMEPAGE}
        />
      );
    }

    return (
      <PostList
        pageStart={page + 1}
        entityCount={entityCount}
        page={page}
        pageSize={pageSize}
        loadRest={showPagination ? this.loadRest : undefined}
        loadMore={showPagination ? undefined : this.loadMore}
        allPosts={showPagination || isEditor ? currentPagePosts : posts}
        currentPagePosts={currentPagePosts}
        isLoading={isLoading}
        showCreatePostAction={showCreatePostAction}
      />
    );
  }

  createPageUrl = (page) => createPageUrl(page, this.props.sectionUrl);

  renderPagination() {
    const { entityCount, pageSize, page, isSite, showPagination } = this.props;
    return (
      <div
        data-hook="feed-pagination-container"
        style={{ display: showPagination ? 'block' : 'none' }}
      >
        <Pagination
          page={page}
          pageSize={pageSize}
          entityCount={entityCount}
          createPageUrl={this.createPageUrl}
          handleNavigation={handleNavigation('/', isSite)}
          showPagination={showPagination}
        />
      </div>
    );
  }

  render() {
    const { isLoaded, isBannerEnabled } = this.props;

    return (
      <Page noSpacing>
        {isBannerEnabled && <FeaturedPostBanner />}
        <AnimatedLoader
          isLoading={!isLoaded}
          isAnimated={this.state.isAnimated}
        >
          {this.renderPosts()}
        </AnimatedLoader>
        {this.renderPagination()}
        {isLoaded && <AppLoaded />}
      </Page>
    );
  }
}

FeedPage.propTypes = {
  category: PropTypes.object,
  currentPagePosts: PropTypes.array,
  entityCount: PropTypes.number,
  excludeContent: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  isBannerEnabled: PropTypes.bool,
  isBlocked: PropTypes.bool,
  isEditor: PropTypes.bool,
  isSite: PropTypes.bool,
  isLoaded: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  lastPage: PropTypes.number,
  newPostId: PropTypes.string,
  page: PropTypes.number,
  pageSize: PropTypes.number.isRequired,
  params: PropTypes.object,
  posts: PropTypes.array,
  showCreatePostAction: PropTypes.bool.isRequired,
  showPagination: PropTypes.bool,
  sectionUrl: PropTypes.string,
  fetchFeedPosts: PropTypes.func,
  layoutType: PropTypes.number.isRequired,
  PostListProGallery: PropTypes.elementType.isRequired,
  canSeeMoreButton: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const currentPagePosts = getFeedPostsByPage(state, ownProps.page);
  const sectionUrl = getSectionUrl(state);
  const showCreatePostAction =
    getIsMobile(state) && isCreatePostButtonVisible(state, ownProps.canSee);
  const {
    sliderShowArrows,
    sliderArrowsSize,
    sliderAutoSlide,
    sliderPauseTime,
    sliderArrowsPosition,
    sliderArrowsColor,
    sliderLoop,
  } = FEED_LAYOUT_PARAMS;

  return {
    posts: getFeedPosts(state),
    currentPagePosts,
    newPostId: state.newPostMessage,
    isLoading: getIsFeedPostsLoading(state),
    isLoaded: getIsFeedPostsLoaded(state),
    showCreatePostAction,
    excludeContent: isExcludePostContentSupported(state),
    isEditor: isEditor(state),
    isSite: isSite(state),
    fetchFeedPosts: actions.fetchFeedPostsPromisified,
    sectionUrl,
    basicParams: getBasicParams(state),
    isBannerEnabled: getIsFeaturedPostBannerEnabled(state),
    canSeeMoreButton: getCanSeeMoreButton(state, ownProps.canSee),
    layoutOptions: {
      showArrows: getAppSettingsValue({
        state,
        key: sliderShowArrows.appSettingsPath,
        fallback: sliderShowArrows.defaultValue,
      }),
      arrowsSize: getAppSettingsValue({
        state,
        key: sliderArrowsSize.appSettingsPath,
        fallback: sliderArrowsSize.defaultValue,
      }),
      autoSlide: getAppSettingsValue({
        state,
        key: sliderAutoSlide.appSettingsPath,
        fallback: sliderAutoSlide.defaultValue,
      }),
      pauseTime: getAppSettingsValue({
        state,
        key: sliderPauseTime.appSettingsPath,
        fallback: sliderPauseTime.defaultValue,
      }),
      arrowsPosition: getAppSettingsValue({
        state,
        key: sliderArrowsPosition.appSettingsPath,
        fallback: sliderArrowsPosition.defaultValue,
      }),
      arrowsColor: getAppSettingsValue({
        state,
        key: sliderArrowsColor.appSettingsPath,
        fallback: sliderArrowsColor.defaultValue,
      }),
      loop: getAppSettingsValue({
        state,
        key: sliderLoop.appSettingsPath,
        fallback: sliderLoop.defaultValue,
      }),
    },
  };
};

export default flowRight(
  withComponents,
  withPermissions,
  withPaginationSettings(SECTION_HOMEPAGE),
  withAuth,
  withDeviceType,
  withLayoutProps(),
  connect(mapRuntimeToProps),
)(FeedPage);
