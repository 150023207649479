import { get } from 'lodash';
import { isSSR } from '@wix/communities-blog-client-common';
import { isInWix } from './is-in-wix';

export const DEFAULT_MOBILE_VIEWPORT_WIDTH = 320;
export const DEFAULT_MOBILE_SCREEN_WIDTH = 320;

export const getMobileScaleFactor = () => {
  if (isSSR() || isInWix()) {
    return 1;
  }

  const screenWidth = get(window, 'screen.width', DEFAULT_MOBILE_SCREEN_WIDTH);
  const viewportWidth = get(
    window,
    'innerWidth',
    DEFAULT_MOBILE_VIEWPORT_WIDTH,
  );
  return screenWidth / viewportWidth;
};

export const getMobileViewportHeight = () => {
  const defaultHeight = 640;
  if (isSSR()) {
    return defaultHeight;
  }

  return get(window, 'innerHeight', defaultHeight);
};
