import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { ComponentsContext } from './components-context';
import createHocName from '../../services/create-hoc-name';

export default function withComponents(WrappedComponent) {
  const WithComponents = (props) => (
    <ComponentsContext.Consumer>
      {(components) => <WrappedComponent {...components} {...props} />}
    </ComponentsContext.Consumer>
  );

  WithComponents.displayName = createHocName(
    'WithComponents',
    WrappedComponent,
  );

  hoistNonReactStatics(WithComponents, WrappedComponent);

  return WithComponents;
}
