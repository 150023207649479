export var FEED_LAYOUT_PARAMS = {
    sliderLoop: {
        wixParam: 'feedSliderLoop',
        appSettingsPath: 'style.booleans.feedSliderLoop',
        defaultValue: false,
    },
    widgetBackgroundColor: {
        wixParam: 'feedBackgroundColor',
        appSettingsPath: 'style.colors.feedBackgroundColor',
        defaultValue: 'color-5',
        defaultOpacity: 0,
    },
    sliderAutoSlide: {
        wixParam: 'feedSliderAutoSlide',
        appSettingsPath: 'style.booleans.feedSliderAutoSlide',
        defaultValue: false,
    },
    sliderPauseTime: {
        wixParam: 'feedSliderPauseTime',
        appSettingsPath: 'style.numbers.feedSliderPauseTime',
        defaultValue: 4,
        minValue: 1,
        maxValue: 10,
    },
    sliderShowArrows: {
        wixParam: 'feedSliderShowArrows',
        appSettingsPath: 'style.booleans.feedSliderShowArrows',
        defaultValue: true,
    },
    sliderArrowsPosition: {
        wixParam: 'feedSliderArrowsPosition',
        appSettingsPath: 'style.numbers.feedSliderArrowsPosition',
        defaultValue: 0,
        values: {
            ON_GALLERY: 0,
            OUTSIDE_GALLERY: 1,
        },
    },
    sliderArrowsSize: {
        wixParam: 'feedSliderArrowsSize',
        appSettingsPath: 'style.numbers.feedSliderArrowsSize',
        defaultValue: 18,
        minValue: 6,
        maxValue: 180,
    },
    sliderArrowsColor: {
        wixParam: 'feedSliderArrowsColor',
        appSettingsPath: 'style.colors.feedSliderArrowsColor',
        defaultValue: 'color-5',
    },
};
