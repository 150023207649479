import { get, flowRight } from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';

import {
  SECTION_CATEGORY,
  getTagBySlug,
  isLayoutProGallery,
  createPageUrl,
} from '@wix/communities-blog-client-common';
import { getIsTagLoaded } from '../../../common/store/is-loaded/is-loaded-selectors';
import { getIsTagLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getTagUrl } from '../../../common/services/get-tag-url';
import {
  getSortedPostsByTagId,
  getPostsByTagIdAndPage,
} from '../../../common/selectors/post-selectors';
import { isExcludePostContentSupported } from '../../../common/selectors/layout-selectors';
import withAuth from '../../../common/hoc/with-auth';
import withLayoutProps from '../../../common/hoc/with-layout-props';
import withPaginationSettings from '../../hoc/with-pagination-settings';
import withPermissions from '../../../common/hoc/with-permissions';
import withTranslate from '../../../common/hoc/with-translate';
import withComponents from '../../../common/components/components-provider/with-components';
import AnimatedLoader from '../../../common/components/animated-loader';
import AppLoaded from '../../../common/components/app-loaded';
import Page from '../../../common/components/page';
import Pagination from '../../../common/containers/pagination';
import PostList from '../../components/post-list';
import { EmptyLayout } from '../../../common/components/layout';
import { handleNavigation } from '../../../common/services/navigation';
import {
  getIsMobile,
  isSite,
} from '../../../common/store/basic-params/basic-params-selectors';
import { isCreatePostButtonVisible } from '../../../common/selectors/blog-menu-button-selectors';
import { getSectionUrl } from '../../../common/store/topology/topology-selectors';
import { getCanSeeMoreButton } from '../../../common/selectors/app-settings-selectors';

const Layout = EmptyLayout;

export class TagsPage extends Component {
  state = {
    isAnimated: !this.props.allPosts.length,
  };

  componentWillUnmount() {
    this.props.setIsLoaded('tag', this.props.tagId, false);
  }

  componentDidUpdate({ isAuthenticated, isBlocked, pageSize, excludeContent }) {
    const { tagId } = this.props;
    if (
      this.props.isAuthenticated !== isAuthenticated ||
      this.props.isBlocked !== isBlocked ||
      this.props.pageSize !== pageSize ||
      (excludeContent && !this.props.excludeContent)
    ) {
      this.fetchPosts(tagId);
    }
  }

  fetchPosts(tagId, page = this.props.page) {
    if (this.props.isBlocked) {
      return;
    }

    this.props.fetchTagPosts({
      tagId,
      page,
      excludeContent: this.props.excludeContent,
    });
  }

  loadMore = (page) => this.fetchPosts(this.props.tagId, page);

  renderPosts() {
    const {
      currentPagePosts,
      allPosts,
      entityCount,
      isTagLoaded,
      isTagLoading,
      showCreatePostAction,
      page,
      layoutType,
      showPagination,
      PostListProGallery,
      canSeeMoreButton,
    } = this.props;

    if (isLayoutProGallery(layoutType)) {
      return (
        <PostListProGallery
          pageStart={page + 1}
          entityCount={entityCount}
          loadMore={showPagination ? undefined : this.loadMore}
          allPosts={showPagination ? currentPagePosts : allPosts}
          currentPagePosts={currentPagePosts}
          isLoading={isTagLoading}
          showCreatePostAction={showCreatePostAction}
          canSeeMoreButton={canSeeMoreButton}
          section={SECTION_CATEGORY}
        />
      );
    }

    return (
      <PostList
        pageStart={page + 1}
        layoutType={layoutType}
        entityCount={entityCount}
        loadMore={showPagination ? undefined : this.loadMore}
        allPosts={showPagination ? currentPagePosts : allPosts}
        currentPagePosts={currentPagePosts}
        isLoading={isTagLoading}
        isLoaded={isTagLoaded}
        showCreatePostAction={showCreatePostAction}
      />
    );
  }

  createPageUrl = (page) => createPageUrl(page, this.props.tagUrl);

  renderPagination() {
    const { entityCount, pageSize, page, isSite, tagPath, showPagination } =
      this.props;

    return (
      <div
        data-hook="tags-pagination-container"
        style={{ display: showPagination ? 'block' : 'none' }}
      >
        <Pagination
          page={page}
          pageSize={pageSize}
          entityCount={entityCount}
          createPageUrl={this.createPageUrl}
          handleNavigation={handleNavigation(tagPath, isSite)}
          showPagination={showPagination}
        />
      </div>
    );
  }

  render() {
    const { isTagLoaded, isTagLoading, allPosts } = this.props;

    const isLoading = isTagLoading && !isTagLoaded;

    return (
      <Page noSpacing>
        <Layout>
          <AnimatedLoader
            isLoading={isLoading && !allPosts.length}
            isAnimated={this.state.isAnimated}
          >
            {this.renderPosts()}
          </AnimatedLoader>
          {!isLoading && this.renderPagination()}
          {isTagLoaded && <AppLoaded />}
        </Layout>
      </Page>
    );
  }
}

TagsPage.propTypes = {
  tagId: PropTypes.string.isRequired,
  tagUrl: PropTypes.string.isRequired,
  tagPath: PropTypes.string.isRequired,
  fetchTagPosts: PropTypes.func.isRequired,
  setIsLoaded: PropTypes.func.isRequired,
  allPosts: PropTypes.array,
  currentPagePosts: PropTypes.array,
  isAuthenticated: PropTypes.bool,
  isBlocked: PropTypes.bool,
  params: PropTypes.object,
  t: PropTypes.func,
  isTagLoading: PropTypes.bool,
  isTagLoaded: PropTypes.bool,
  isSite: PropTypes.bool,
  showCreatePostAction: PropTypes.bool.isRequired,
  layoutType: PropTypes.number.isRequired,
  excludeContent: PropTypes.bool,
  pageSize: PropTypes.number,
  entityCount: PropTypes.number,
  showPagination: PropTypes.bool,
  PostListProGallery: PropTypes.elementType.isRequired,
  canSeeMoreButton: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => {
  const tagSlug = get(ownProps, ['params', 'tagSlug']);
  const tag = getTagBySlug(state, tagSlug);
  const tagId = tag.id;

  const sectionUrl = getSectionUrl(state);
  const tagUrl = getTagUrl(sectionUrl, tagSlug);

  const showCreatePostAction =
    getIsMobile(state) && isCreatePostButtonVisible(state, ownProps.canSee);

  return {
    tagId,
    tagPath: 'tags',
    tagUrl,
    allPosts: tagId ? getSortedPostsByTagId(state, tagId) : [],
    currentPagePosts: tagId
      ? getPostsByTagIdAndPage(state, tagId, ownProps.page)
      : [],
    isTagLoading: getIsTagLoading(state, tagId),
    isTagLoaded: getIsTagLoaded(state, tagId),
    isSite: isSite(state),
    showCreatePostAction,
    excludeContent: isExcludePostContentSupported(state),
    setIsLoaded: actions.setIsLoaded,
    fetchTagPosts: actions.fetchTagPostsPromisified,
    canSeeMoreButton: getCanSeeMoreButton(state, ownProps.canSee),
  };
};

export default flowRight(
  withComponents,
  withPermissions,
  withPaginationSettings(SECTION_CATEGORY),
  withLayoutProps(),
  withAuth,
  withTranslate,
  connect(mapRuntimeToProps),
)(TagsPage);
